
import React from 'react'
import styled from 'styled-components'
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import { education, experiences } from '../../data/constants';
// import EducationCard from '../Cards/EducationCard';
import './Education.css'
import { RxCross2 } from "react-icons/rx";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    align-items: center;
    padding: 0px 0px 60px 0px;
    @media (max-width: 960px) {
        padding: 0px;
    }
`;

const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1350px;
    padding: 40px 0px 0px 0px;
    gap: 12px;
    @media (max-width: 960px) {
        flex-direction: column;
    }
`;

const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 20px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
      margin-top: 12px;
      font-size: 32px;
  }
`;

const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        margin-top: 12px;
        font-size: 16px;
    }
`;

// const TimelineSection = styled.div`
//     width: 100%;
//     max-width: 1000px;
//     margin-top: 10px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     gap: 12px;
//     @media (max-width: 660px) {
//         align-items: end;
//     }
// `;



const index = () => {
    return (
        <Container id="education">
            <Wrapper>
                {/* <Title>Education</Title>
                <Desc>
                    My education has been a journey of self-discovery and growth. My educational details are as follows.
                </Desc> */}
                {/* <TimelineSection>
                    <Timeline>
                        {education.map((education,index) => (
                            <TimelineItem >
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <EducationCard education={education}/>
                                </TimelineContent>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" color="secondary" />
                                    {index !== experiences.length  && <TimelineConnector style={{ background: '#854CE6' }} />}
                                </TimelineSeparator>
                            </TimelineItem>
                        ))}
                    </Timeline>

                </TimelineSection> */}
                <div class="bg">
                    <h1>Pricings</h1>
                </div>
                <div className='flex'>
                    <div class="nft">
                        <div class='main'>
                            <img class='tokenImage' src="https://images.unsplash.com/photo-1621075160523-b936ad96132a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="NFT" />
                            <h3>Crisp FrontEnd Website</h3>
                            <p class='description'>1-3 Pages</p>
                            <p class='description'>Startup/Business Landing Page</p>
                            <p class='description'>Responsive + Full Customization</p>
                            <p class='description'>SEO + Google Analytics + Hosting</p>
                            <p class='description'>Intelligent Auto SMS, Emails Features <RxCross2 color="#D22B2B" fontSize="1.5em" />
                            </p>
                            <p class='description'>Database Mabagement + Dashboard <RxCross2 color="#DE3163" fontSize="1.5em" /></p>
                            <div class='tokenInfo'>
                                <div class="price">
                                    <ins>₹</ins>
                                    <p>4500 INR</p>
                                </div>
                                <div class="duration">
                                    <ins>◷</ins>
                                    {/* <p>3 days </p> */}
                                </div>
                            </div>
                            <hr />
                            <div class='creator'>
                                <div class='wrapper'>
                                    <img src="https://codewithsahil.vercel.app/static/media/logo.6d999d05d906b9bbf4a3.png" alt="Creator" />
                                </div>
                                <p ><ins></ins> 2k more for urgent work</p>
                            </div>
                        </div>
                    </div>
                    <div class="nft">
                        <div class='main'>
                            <img class='tokenImage' src="https://images.unsplash.com/photo-1541701494587-cb58502866ab?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="NFT" />
                            <h3>Excellent Full-Stack</h3>
                            <p class='description'>1-5 Pages</p>
                            <p class='description'>E Commerce Website / Blog</p>
                            <p class='description'>Full Customization Admin Panel</p>
                            <p class='description'>Intelligent Email + SMS + Whatsapp Automations
                            </p>
                            <p class='description'>Payment Gateway Integration
                            </p>
                            <p class='description'>All features of Crisp FrontEnd Website
                            </p>
                            {/* <p class='description'>Database Mabagement + Dashboard <RxCross2 color="#DE3163" fontSize="1.5em" /></p> */}
                            <div class='tokenInfo'>
                                <div class="price">
                                    <ins>₹</ins>
                                    <p>15999 INR</p>
                                </div>
                                <div class="duration">
                                    <ins>◷</ins>
                                    <p>Popular</p>
                                </div>
                            </div>
                            <hr />
                            <div class='creator'>
                                <div class='wrapper'>
                                    <img src="https://codewithsahil.vercel.app/static/media/logo.6d999d05d906b9bbf4a3.png" alt="Creator" />
                                </div>
                                <p><ins></ins>4k more for urgent work</p>
                            </div>
                        </div>
                    </div>
                    <div class="nft">
                        <div class='main'>
                            <img class='tokenImage' src="https://images.unsplash.com/photo-1503437313881-503a91226402?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="NFT" />
                            <h3>Elite Full Stack</h3>
                            <p class='description'>1-13 Pages</p>
                            <p class='description'>Speed Optimization</p>
                            <p class='description'>Full Content Management System</p>
                            <p class='description'>Caching + Advanced Security
                            </p>
                            <p class='description'>Database Mabagement + Dashboard </p>
                            <p class='description'>All Features of Excellent Plan </p>
                            <div class='tokenInfo'>
                                <div class="price">
                                    <ins>₹</ins>
                                    <p>29990 INR</p>
                                </div>
                                <div class="duration">
                                    <ins>◷</ins>
                                    {/* <p>6-7 days </p> */}
                                </div>
                            </div>
                            <hr />
                            <div class='creator'>
                                <div class='wrapper'>
                                    <img src="https://codewithsahil.vercel.app/static/media/logo.6d999d05d906b9bbf4a3.png" alt="Creator" />
                                </div>
                                <p><ins></ins>5k more for urgent work</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </Container>
    )
}

export default index